<template>
  <div class="pzcl">
    <div class="top">
      <div class="left">
        <div class="info" v-for="item in pzInfo" :key="item.name">
          <div class="title">{{item.name}}</div>
          <div class="text">
            <div class="text-wrapper" v-for="(num, index) in item.data" :key="index">
              <div class="num">{{num}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="title">预计产量：</div>
        <div class="mon"
             v-for="item in month"
             :key="item.value"
             :class="{'active': curMon === item.value}"
             @click="monthHandle(item)"
        >{{item.label}}月</div>
      </div>
    </div>
    <div class="bottom">
      <div class="plants-wrapper">
        <div class="icon-up" :class="{'disable': disableUp}" @click="slideUp"></div>
        <div class="plants-cnt">
          <div ref="plants" class="plants">
            <div class="plants-item"
                 v-for="item in plants"
                 :key="item.id"
                 :class="{'active': curPlant === item.id}"
                 @click="plantHandle(item)"
            >{{item.name}}</div>
          </div>
        </div>
        <div class="icon-down" :class="{'disable': disableDown}" @click="slideDown"></div>
      </div>
      <div class="map">
        <plant-map ref="plantMapRef" :chart-option="chartOption" has-drop has-county @selectCity="mapSelect" @selectMarker="markerSelect"></plant-map>
      </div>
      <div class="charts">
        <div class="pub_border">
          <div class="pub_title" @click="chartBarFlag = true">产量分布</div>
          <div class="chart" id="bar"></div>
        </div>
        <div class="pub_border">
          <div class="pub_title" @click="chartLineFlag = true">预计产量分布</div>
          <div class="chart" id="line"></div>
        </div>
      </div>
    </div>
    <Enterprise-info ref="enterpriseRef"></Enterprise-info>
    <supply-list ref="supplyRef"></supply-list>
    <base-dialog
            width="1400px"
            height="500px"
            label="面积及产量分布"
            :show-close="true"
            v-show="chartBarFlag"
            @closeDialog="chartBarFlag = false"
    >
      <div id="bar1" style="width: 1400px;height: 450px;padding-bottom: 20px"></div>
    </base-dialog>
    <base-dialog
            width="1400px"
            height="500px"
            label="预计产量分布"
            :show-close="true"
            v-show="chartLineFlag"
            @closeDialog="chartLineFlag = false"
    >
      <div id="line1" style="width: 1400px;height: 450px;padding-bottom: 20px"></div>
    </base-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
import EnterpriseInfo from '@/components/enterprise' //企业信息
import SupplyList from '@/components/supplyList' //今日供应列表
import BaseDialog from '@/components/dialog/baseDialog' //弹框
import { getPlantsList, getPlantTotalInfo, getPlantYield, getPlantDistribution, getMapAreaInfo } from '@/api/cyfb'
import { ERR_OK } from '@/utils/config'
import plantMap from '../../components/map/map'
export default {
    components: { EnterpriseInfo, SupplyList, plantMap, BaseDialog },
    data() {
        return {
            disableUp: true,
            disableDown: false,
            pzInfo: [{
                name: '种植品种(种)',
                data: ''
            }, {
                name: '总面积(万亩)',
                data: ''
            }, {
                name: '总产量(吨)',
                data: ''
            }],
            curMon: '',
            month: [],
            plants: [],
            curPlant: '',
            translateY: 0,
            cityCode: '',
            chartOption: {
                tooltip: (params) => {
                    let data = params.data
                    let html = `<div style="padding:0;width: 280px;height: 150px;background: rgba(2,18,81,0.68);">
                        <div style="width: 254px;height: 23px;line-height: 23px;text-indent: 1em;font-size: 13px;color: #fff;background: linear-gradient(90deg,rgba(45,125,255,1), rgba(45,125,255,0))">${data.name}</div>
                        <div style="margin-left: 1em;line-height: 30px"><span>联系人：<span style="color:#0CFED9;font-size: 18px">${data.contacts || ''}</span></span></div>
                        <div style="margin-left: 1em;line-height: 30px"><span>联系电话：<span style="color:#0CFED9;font-size: 18px">${data.phone || ''}</span></span></div>
                        <div style="margin-left: 1em;line-height: 30px"><span>供应量：<span style="color:#0CFED9;font-size: 18px">${data.yield || 0}</span>吨</span></div>
                        <div style="margin-left: 1em;line-height: 30px"><span>时间：<span style="color:#0CFED9;font-size: 18px">${data.plant_time.substr(0, 10)}</span></span></div>
                    </div>`
                    return html
                }
            },
            chartBarFlag: false,
            chartLineFlag: false,
        }
    },
    mounted() {
        this.init()
        this.initBar()
        this.initLine()
    },
    methods: {
        // 初始化
        async init() {
            await this.getPlantsList()
        },
        // 获取品种列表
        getPlantsList() {
            return getPlantsList().then(res => {
                if (res.code === ERR_OK) {
                    this.plants = res.rows
                    this.curPlant = res.rows[0].id
                    this.pzInfo[0].data = res.total.toString()
                    this.plantHandle(res.rows[0])
                }
            })
        },
        // 获取某个品种某个月份的总产量和总面积
        getPlantTotalInfo() {
            return getPlantTotalInfo({
                plantId: this.curPlant,
                yearMonth: this.curMon,
                cityCode: this.cityCode
            }).then(res => {
                if (res.code === ERR_OK) {
                    this.pzInfo[1].data = ((res.data.area || 0) / 10000).toFixed(0)
                    this.pzInfo[2].data = (res.data.yield || 0).toFixed(0)
                }
            })
        },
        // 某个品种未来6个月产量
        getPlantYield() {
            return getPlantYield({
                plantId: this.curPlant,
                cityCode: this.cityCode
            }).then(res => {
                if (res.code === ERR_OK) {
                    this.lineReload(res.data)
                }
            })
        },
        // 获取12地市面积及产量分布
        getPlantDistribution() {
            return getPlantDistribution({
                plantId: this.curPlant,
                yearMonth: this.curMon,
                cityCode: this.cityCode
            }).then(res => {
                if (res.code === ERR_OK) {
                    this.barReload(res.data)
                }
            })
        },
        slideUp() {
            if (this.translateY === 0) {
                this.disableUp = true
                return
            }
            this.disableDown = false
            this.translateY += 80
            this.$refs.plants.style.transform = `perspective(85px) scale(1.15, 1) rotateY(2deg) translateY(${this.translateY}px)`
        },
        slideDown() {
            let height = this.plants.length * 80 * 1.15
            if (-this.translateY > height / 2) {
                this.disableDown = true
                return
            }
            this.disableUp = false
            this.translateY -= 80
            this.$refs.plants.style.transform = `perspective(85px) scale(1.15, 1) rotateY(2deg) translateY(${this.translateY}px)`
        },
        // 品种选择
        async plantHandle(obj) {
            this.curPlant = obj.id
            await this.getPlantYield()
            await this.getPlantTotalInfo()
            await this.getPlantDistribution()
            await this.getMapAreaInfo()
        },
        // 地图市区县选择
        async mapSelect(obj) {
            this.cityCode = obj.code
            await this.getPlantYield()
            await this.getPlantTotalInfo()
            await this.getPlantDistribution()
            await this.getMapAreaInfo()
        },
        // 地图数据
        getMapAreaInfo() {
            return getMapAreaInfo({
                plantId: this.curPlant,
                cityId: this.cityCode,
                yearMonth: this.curMon,
                pageSize: 10000
            }).then(res => {
                if (res.code === ERR_OK) {
                    let obj = this.plants.find(item => item.id === this.curPlant)
                    let data = res.data.rows.map(item => {
                        return {
                            value: [item.longitude, item.latitude],
//                            value: [119.596224, 39.943458],
                            ...item
                        }
                    })
                    this.$refs.plantMapRef.addMarker([{
                        name: obj.name,
                        url: `image://${sysUrl}${obj.url}`,
                        size: 40,
                        data: data
                    }])
                }
            })
        },
        // 月份选择
        monthHandle(obj) {
            this.curMon = obj.value
            this.getPlantTotalInfo()
            this.getPlantDistribution()
            this.getMapAreaInfo()
        },
        // marker点击
        markerSelect(obj) {
            this.$refs.enterpriseRef.show(obj)
        },
        initBar(){//面积及产量分布
            /*this.bar = this.$echarts.init(document.getElementById('bar'));
            this.bar1 = this.$echarts.init(document.getElementById('bar1'));
            let legendData = ['面积', '产量'];
            let fontColor = '#B9C3D3';
            let option = {
                backgroundColor: 'transparent',
                grid: {
                    left: '8%',
                    right: '8%',
                    top: '12%',
                    bottom: '24%',
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                        lineStyle: {
                            color: fontColor,
                        },
                    },
                },
                legend: {
                    data: legendData,
                    right: '8%',
                    top: '2%',
                    textStyle: {
                        color: fontColor,
                    },
                },
                dataZoom: [
                    {
                        type: 'slider',
                        realtime: true,
                        startValue: 0,
                        endValue: 4,
                        xAxisIndex: [0],
                        bottom: '6%',
                        left: '8%',
                        height: 10,
                        borderColor: 'rgba(0,0,0,0)',
                        textStyle: {
                            color: '#05D5FF',
                        },
                    }
                ],
                xAxis: {
                    type: 'category',

                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: fontColor,
                        },
                    },
                    offset: 0,
                    axisTick: {
                        show: false,
                        alignWithLabel: true,
                        lineStyle: {
                            color: fontColor,
                        },
                    },
                    axisLabel: {
                        fontSize: 16,
                        color: fontColor,
//                        rotate: 30
                    },
                    data: [],
                },
                yAxis: {
                    name: '万亩/吨',
                    nameTextStyle:{
                        color: fontColor,
                    },
                    type: 'value',
                    min: 0,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: fontColor,
                        },
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            type: 'dotted',
                            color: fontColor,
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        fontSize: 16,
                        color: fontColor
                    },
                    boundaryGap: ['20%', '20%'],
                },
                series: [
                    {
                        name: legendData[0],
                        type: 'bar',
                        smooth: true,
                        symbol: 'none',
                        showSymbol: false,
                        symbolSize: 8,
                        barWidth: 10,
                        itemStyle: {
                            color: {
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                type: 'linear',
                                global: false,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: 'rgba(0,168,255,1)',
                                    },
                                    {
                                        offset: 1,
                                        color: 'rgba(0,168,255,0)',
                                    },
                                ],
                            },
                        },
                        data: [],
                    },
                    {
                        name: legendData[1],
                        type: 'bar',
                        smooth: true,
                        symbol: 'none',
                        showSymbol: false,
                        symbolSize: 8,
                        barWidth: 10,
                        itemStyle: {
                            color: {
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                type: 'linear',
                                global: false,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: 'rgba(90,231,153,1)',
                                    },
                                    {
                                        offset: 1,
                                        color: 'rgba(90,231,153,0)',
                                    },
                                ],
                            },
                        },
                        data: [],
                    },
                ],
            };
            this.bar.setOption(option)
            this.bar1.setOption(option);
            this.bar.on('click', (params) => {
                this.$refs.supplyRef.show({
                    areaCode: this.cityCode,
                    selTime: this.curMon,
                    plantId: this.curPlant
                })
            })*/
          this.bar = this.$echarts.init(document.getElementById('bar'));
          this.bar1 = this.$echarts.init(document.getElementById('bar1'));
          let legendData = [ '产量'];
          let fontColor = '#B9C3D3';
          let option = {
            backgroundColor: 'transparent',
            grid: {
              left: '8%',
              right: '8%',
              top: '12%',
              bottom: '24%',
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow',
                lineStyle: {
                  color: fontColor,
                },
              },
            },
            legend: {
              data: legendData,
              right: '8%',
              top: '2%',
              textStyle: {
                color: fontColor,
              },
            },
            dataZoom: [
              {
                type: 'slider',
                realtime: true,
                startValue: 0,
                endValue: 4,
                xAxisIndex: [0],
                bottom: '6%',
                left: '8%',
                height: 10,
                borderColor: 'rgba(0,0,0,0)',
                textStyle: {
                  color: '#05D5FF',
                },
              }
            ],
            xAxis: {
              type: 'category',

              axisLine: {
                show: true,
                lineStyle: {
                  color: fontColor,
                },
              },
              offset: 0,
              axisTick: {
                show: false,
                alignWithLabel: true,
                lineStyle: {
                  color: fontColor,
                },
              },
              axisLabel: {
                fontSize: 16,
                color: fontColor,
//                        rotate: 30
              },
              data: [],
            },
            yAxis: {
              name: '万亩/吨',
              nameTextStyle:{
                color: fontColor,
              },
              type: 'value',
              min: 0,
              axisLine: {
                show: true,
                lineStyle: {
                  color: fontColor,
                },
              },
              splitLine: {
                show: false,
                lineStyle: {
                  type: 'dotted',
                  color: fontColor,
                },
              },
              axisTick: {
                show: false,
              },
              axisLabel: {
                fontSize: 16,
                color: fontColor
              },
              boundaryGap: ['20%', '20%'],
            },
            series: [
              {
                name: legendData[0],
                type: 'bar',
                smooth: true,
                symbol: 'none',
                showSymbol: false,
                symbolSize: 8,
                barWidth: 10,
                itemStyle: {
                  color: {
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    type: 'linear',
                    global: false,
                    colorStops: [
                      {
                        offset: 0,
                        color: 'rgba(0,168,255,1)',
                      },
                      {
                        offset: 1,
                        color: 'rgba(0,168,255,0)',
                      },
                    ],
                  },
                },
                data: [],
              },
            ],
          };
          this.bar.setOption(option)
          this.bar1.setOption(option);
          this.bar.on('click', (params) => {
            this.$refs.supplyRef.show({
              areaCode: this.cityCode,
              selTime: this.curMon,
              plantId: this.curPlant
            })
          })
        },
        barReload(data){//面积及产量分布重载
            let obj = {
                xAxis: {
                    data: data.xData
                },
                series:[{
                    data: data.yData.yield.map(item => item.toFixed(2))
                }]
            }
            this.bar.setOption(obj)
            this.bar1.setOption(obj)
        },
        initLine(){//预计产量分布
            this.line = this.$echarts.init(document.getElementById('line'));
            this.line1 = this.$echarts.init(document.getElementById('line1'));
            let legendData = ['预计产量'];
            let fontColor = '#B9C3D3';
            let option = {
                backgroundColor: 'transparent',
                grid: {
                    left: '8%',
                    right: '2%',
                    top: '12%',
                    bottom: '15%',
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                        lineStyle: {
                            color: '#57617B',
                        },
                    },
                },
                legend: {
                    data: legendData,
                    right: '8%',
                    top: '2%',
                    textStyle: {
                        color: fontColor,
                    },
                },
                xAxis: {
                    type: 'category',
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: fontColor,
                        },
                    },
                    offset: 0,
                    axisTick: {
                        show: false,
                        alignWithLabel: true,
                        lineStyle: {
                            color: fontColor,
                        },
                    },
                    axisLabel: {
                        fontSize: 16,
                        color:fontColor
                    },
                    data: [],
                },
                yAxis: {
                    name: '吨',
                    nameTextStyle:{
                        color: fontColor,
                    },
                    type: 'value',
                    min: 0,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: fontColor,
                        },
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            type: 'dotted',
                            color: fontColor,
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        fontSize: 16,
                        color: fontColor
                    },
                    boundaryGap: ['20%', '20%'],
                },
                series: [
                    {
                        name: legendData[0],
                        type: 'line',
                        smooth: true,
                        symbol: 'none',
                        showSymbol: false,
                        symbolSize: 8,
                        itemStyle: {
                            color: '#FFBA00',
                        },
                        areaStyle: {
                            opacity: 0.8,
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: 'rgba(255,186,0,0.4)'
                                },
                                {
                                    offset: 1,
                                    color: 'rgba(255,186,0,0)'
                                }
                            ])
                        },
                        data: [],
                    }
                ],
            };
            this.line.setOption(option);
            this.line1.setOption(option);
        },
        lineReload(data){//预计产量分布重载
            if (!this.month.length) {
                this.month = data.xData.map(mon => {
                    return {
                        label: mon.substr(4),
                        value: mon
                    }
                })
//            this.month = [{
//                label: '12',
//                value: '202212'
//            }, {
//                label: '01',
//                value: '202301'
//            }, {
//                label: '03',
//                value: '202302'
//            }]
                this.curMon = this.month[0].value
            }

            let obj = {
                xAxis: {
                    data: data.xData.map(item => `${item.substr(0,4)}-${item.substr(4)}`)
                },
                series:[{
                    data: data.yData.map(item => item)
                }]
            }
            this.line.setOption(obj)
            this.line1.setOption(obj)
        },
    }
};
</script>

<style scoped lang='scss'>
  .pzcl {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0 80px;
    .top {
      display: flex;
      height: 200px;
      box-sizing: border-box;
      padding-top: 70px;
      .left {
        width: 42%;
        display: flex;
        justify-content: space-around;
        box-sizing: border-box;
        padding-right: 40px;
        .info {
          text-align: center;
          .title {
            font-size: 22px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 40px;
          }
          .text {
            display: flex;
            justify-content: center;
            .text-wrapper {
              width: 45px;
              height: 65px;
              text-align: center;
              line-height: 65px;
              font-size: 40px;
              background: url("./images/num-bg.png") no-repeat center/cover;
              margin-right: 4px;
              .num {
                background-image: linear-gradient(0deg, #FED471 0%, #FDF79E 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }
            }
          }
        }
      }
      .right {
        flex: 1;
        height: 50px;
        position: relative;
        margin: 30px 80px;
        display: flex;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          transform-origin: bottom;
          transform: perspective(100px) scale(1.1,1.3) rotateX(18deg);
          background: linear-gradient(180deg, rgba(79,91,254,0), rgba(12,92,255,0.42));
          box-shadow: 0px 7px 7px 0px rgba(1,7,38,0.4);
        }
        .title {
          color: #fff;
          font-size: 22px;
        }
        .mon {
          width: 50px;
          height: 28px;
          background: #0D2C5E;
          border: 1px solid #1890FF;
          border-radius: 14px;
          cursor: pointer;
          margin-left: 10px;
          text-align: center;
          line-height: 28px;
          font-size: 16px;
          position: relative;
          z-index: 2;
          &:hover,
          &.active {
            background: #1890FF;
          }
        }
      }
    }
    .bottom {
      flex: 1;
      display: flex;
      box-sizing: border-box;
      padding-right: 80px;
      .plants-wrapper {
        width: 240px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon-up,
        .icon-down {
          width: 62px;
          height: 54px;
          background-repeat: no-repeat;
          background-size: center/cover;
          cursor: pointer;
          &.disable {
            cursor: not-allowed;
          }
        }
        .icon-up {
          background-image: url("./images/icon-up-active.png");
          &.disable {
            background-image: url("./images/icon-up-disabled.png");
          }
        }
        .icon-down {
          background-image: url("./images/icon-down-active.png");
          &.disable {
            background-image: url("./images/icon-down-disabled.png");
          }
        }
        .plants-cnt {
          height: 574px;
          overflow: hidden;
          margin-bottom: 6px;
          .plants {
            transform-origin: left;
            transform: perspective(85px) scale(1.15, 1) rotateY(2deg);
            transition: all .3s;
            .plants-item {
              width: 250px;
              height: 64px;
              background-repeat: no-repeat;
              background-size: center/cover;
              background-image: url("./images/card.png");
              line-height: 72px;
              text-align: center;
              font-size: 23px;
              color: #32EEFF;
              cursor: pointer;
              margin-bottom: 10px;
              &:hover,
              &.active {
                color: #fff;
                background-image: url("./images/card-active.png");
              }
            }
          }
        }
      }
      .map {
        flex: 1;
      }
      .charts {
        width: 660px;
        .pub_border {
          height: 320px;
          margin-bottom: 26px;
          background: linear-gradient(180deg, rgba(14,47,82,0), rgba(14,47,82,0.76));
          .chart {
            height: 273px;
          }
        }
      }
    }
  }
</style>
