<template>
    <div class="qyfb">
        <div class="top">
            <div class="info" v-for="item in qyInfo" :key="item.name">
                <div class="title">{{ item.name }}</div>
                <div class="text">
                    <div class="num">{{ item.count }}</div>家
                </div>
            </div>
        </div>
        <div class="bottom">
            <div class="company-wrapper">
                <div class="icon-up" :class="{ 'disable': disableUp }" @click="slideUp"></div>
                <div class="company-cnt">
                    <div ref="company" class="company">
                        <div class="company-item" v-for="(item, index) in company" :key="item.id"
                            :class="{ 'active': curMenu === item.id }" :style="{ 'top': `${index * 74}px` }">
                            <div @click="menuHandle(item.id, '')">{{ item.lable }}</div>
                            <div class="sub-menu" v-if="item.child && item.child.length">
                                <div class="sub-menu-item" v-for="menu in item.child" :key="menu.id"
                                    :class="{ 'active': curSubMenu === menu.id }"
                                    @click.stop="menuHandle(item.id, menu.id)">
                                    {{ menu.lable }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="icon-down" :class="{ 'disable': disableDown }" @click="slideDown"></div>
            </div>
            <div class="map">
                <map-chart ref="companyMapRef" :chart-option="chartOption" has-drop @selectCity="mapSelect"
                    @selectMarker="markerSelect"></map-chart>
            </div>
            <div class="charts">
                <div class="pub_border">
                    <div class="pub_title" @click="chartPieFlag = true">企业类型分布</div>
                    <div class="chart" id="pie"></div>
                </div>
                <div class="pub_border">
                    <div class="pub_title" @click="chartBarFlag = true">企业各市分布</div>
                    <div class="chart" id="bar"></div>
                </div>
            </div>
        </div>
        <Enterprise-info ref="enterpriseRef"></Enterprise-info>
        <enterprise-list ref="enterpriseList"></enterprise-list>
        <base-dialog width="1200px" height="500px" label="企业类型分布" :show-close="true" v-show="chartPieFlag"
            @closeDialog="chartPieFlag = false">
            <div id="pie1" style="width: 1200px;height: 450px"></div>
        </base-dialog>
        <base-dialog width="1200px" height="500px" label="企业各市分布" :show-close="true" v-show="chartBarFlag"
            @closeDialog="chartBarFlag = false">
            <div id="bar1" style="width: 1200px;height: 450px;padding-bottom: 20px"></div>
        </base-dialog>
    </div>
</template>

<script type="text/ecmascript-6">
import EnterpriseInfo from '@/components/enterprise' //企业信息
import EnterpriseList from '@/components/enterpriseList' //企业列表
import mapChart from '../../components/map/mapCopy'
import { getCompanyType, getCompanyInfo, getCompanyCount, getCompanyList } from '@/api/cyfb'
import { ERR_OK } from '@/utils/config'
import areaCode from '../../../public/config/area.json'
import BaseDialog from '@/components/dialog/baseDialog' //弹框

const PARENT_CODE = ''
export default {
    components: { EnterpriseInfo, EnterpriseList, mapChart, BaseDialog },
    data() {
        return {
            qyInfo: [],
            company: [],
            curMenu: '',
            curSubMenu: '',
            cityCode: PARENT_CODE,
            pieData: [],
            top: 0,
            disableUp: true,
            disableDown: false,
            chartOption: {
                tooltip: (params) => {
                    console.log(params,'params---------')
                    let data = params.data
                    let html = ''
                        html = `<div style="padding:0;width: 280px;height: 120px;background: rgba(2,18,81,0.68);">
                            <div style="width: 254px;height: 23px;line-height: 23px;text-indent: 1em;font-size: 13px;color: #fff;background: linear-gradient(90deg,rgba(45,125,255,1), rgba(45,125,255,0))">${data.name}</div>
                            <div style="margin-left: 1em;line-height: 30px"><span>联系人：<span style="color:#0CFED9;font-size: 18px">${data.contacts || ''}</span></span></div>
                            <div style="margin-left: 1em;line-height: 30px"><span>联系电话：<span style="color:#0CFED9;font-size: 18px">${data.phone || ''}</span></span></div>
                            <div style="margin-left: 1em;line-height: 30px"><span>供应量：<span style="color:#0CFED9;font-size: 18px">${data.yield || 0}</span>吨</span></div>
                        </div>`

                    return html
                }
            },
            chartPieFlag: false,
            chartBarFlag: false,
        }
    },
    mounted() {
        this.init()
        this.initPie()
        this.initBar()
    },
    methods: {
        abcccc(row) {
            alert(12)
        },
        // 初始化数据
        async init() {
            await this.getCompanyType()
            await this.getTopCompanyInfo()
        },
        // 企业类型列表
        getCompanyType() {
            return getCompanyType().then(res => {
                if (res.code === ERR_OK) {
                    this.company = res.data;
                    this.company.unshift({
                        id: '',
                        lable: '全部企业'
                    })
                    this.menuHandle(res.data[0].id, '')
                }
            })
        },
        // 顶部统计(企业类型分布)
        getTopCompanyInfo() {
            return getCompanyInfo({
                ids: '',
                areaCode: this.cityCode
            }).then(res => {
                if (res.code === ERR_OK) {
                    this.qyInfo = res.data
                }
            })
        },
        // 顶部统计(企业类型分布)
        getCompanyInfo(id) {
            return getCompanyInfo({
                //                    ids: id,
                ids: '',
                areaCode: this.cityCode
            }).then(res => {
                if (res.code === ERR_OK) {
                    this.pieData = res.data
                    this.pieReload(res.data)
                }
            })
        },
        slideUp() {
            if (this.top === 0) {
                this.disableUp = true
                return
            }
            this.disableDown = false
            this.top += 74
            this.$refs.company.style.top = `${this.top}px`
        },
        slideDown() {
            let height = this.company.length * 74
            if (-this.top > height / 2) {
                this.disableDown = true
                return
            }
            this.disableUp = false
            this.top -= 74
            this.$refs.company.style.top = `${this.top}px`
        },
        menuHandle(menuId, subMenuId) {
            this.curMenu = menuId
            this.curSubMenu = subMenuId
            if (subMenuId) {
                this.getCompanyCount(subMenuId)
                this.getCompanyList(subMenuId)
                this.getCompanyInfo(subMenuId)
            } else {
                this.getCompanyCount(menuId)
                this.getCompanyList(menuId)
                this.getCompanyInfo(menuId)
            }
        },
        // 地图市区县选择
        async mapSelect(obj) {
            this.cityCode = obj.code || PARENT_CODE
            this.menuHandle(this.curMenu, this.curSubMenu)
        },
        // marker点击
        markerSelect(obj) {
            this.$refs.enterpriseRef.show(obj)
        },
        // 获取企业分布图表
        getCompanyCount(id) {
            return getCompanyCount({
                areaCode: this.cityCode,
                companyTypeId: id
            }).then(res => {
                // console.log(res, 'llllllllllllllllll');
                if (res.code === ERR_OK) {
                    this.barReload(res.data)
                }
            })
        },
        // 获取企业上图点位
        getCompanyList(id) {
            return getCompanyList({
                areaCode: this.cityCode,
                companyTypeId: id,
                pageSize: 1000000
            }).then(res => {
                if (res.code === ERR_OK) {
                    let marker = []
                    res.rows.forEach(item => {
                        let obj = {
                            name: item.name,
                            url: `image://${require('@/assets/images/qyfb_marker.png')}`,
                            size: [39, 51],
                            data: [{
                                value: [item.longitude, item.latitude],
                                //                                    value: [119.596224, 39.943458],
                                ...item
                            }]
                        }
                        marker.push(obj)
                    })
                    this.$refs.companyMapRef.addMarker(marker.filter(item => item.name).filter(item => item.data[0].longitude && item.data[0].latitude))
                }
            })
        },
        initPie() {//面积及产量分布
            this.pie = this.$echarts.init(document.getElementById('pie'));
            this.pie1 = this.$echarts.init(document.getElementById('pie1'));
            let color = ["#00ffff", "#00cfff", "#006ced", "#ffe000", "#ffa800", "#ff5b00", "#ff3000",];
            let option = {
                // backgroundColor: '#fff',
                color: color,
                tooltip: {
                    trigger: 'item',
                },
                legend: {
                    icon: "rectangle",
                    orient: "horizontal",
                    selectMode: 'single',
                    // x: 'left',
                    data: [],
                    right: 100,
                    //                        left: 100,
                    top: 'center',
                    align: "left",
                    textStyle: {
                        color: "#fff",
                        fontSize: 16,
                        rich: {
                            value: {
                                color: '#00DEFF',
                                fontSize: 24,
                                padding: [0, 0, 0, 10],
                            }
                        }
                    },
                    itemGap: 20,
                    formatter: (item) => {
                        const value = this.pieData.length ? this.pieData.find(v => v.name === item).count : 0
                        return (
                            `${item}{value|${value}家}`
                        );
                    },
                },
                graphic: {
                    elements: [{
                        type: 'image',
                        z: 4,
                        style: {
                            image: require('@/assets/images/pzmj_pie.png'),
                            width: 123,
                            height: 123,
                        },
                        left: '12.6%',
                        top: 'center',
                    }],
                },
                series: [
                    {
                        name: '企业类型分布',
                        type: 'pie',
                        clockWise: false,
                        radius: ['50%', '65%'],
                        center: ['22%', '50%'],
                        data: [],
                        itemStyle: {
                            borderColor: 'rgba(14,47,82,0.1)',
                            borderWidth: 5,
                        },
                        label: {
                            show: false,
                        },
                    },
                ]
            };
            this.pie.setOption(option);
            this.pie1.setOption(option);
            this.pie.on('click', (params) => {
                this.$refs.enterpriseList.show({
                    type: 2,
                    title: params.data.name,
                    parentInfo: {
                        code: PARENT_CODE
                    },
                    param: {
                        companyTypeId: this.company.find(item => item.lable === params.data.name).id,
                        parentAreaCodes: this.cityCode
                    }
                })
            })
        },
        pieReload(originData) {//面积及产量分布重载
            let data = []
            for (let i = 0; i < originData.length; i++) {
                data.push(
                    {
                        value: originData[i].count,
                        name: originData[i].name,
                    },
                    {
                        value: 2,
                        name: "",
                        itemStyle: {
                            normal: {
                                label: {
                                    show: false,
                                },
                                labelLine: {
                                    show: false,
                                },
                                color: "rgba(0, 0, 0, 0)",
                                borderColor: "rgba(0, 0, 0, 0)",
                                borderWidth: 0,
                            },
                        },
                    }
                );
            }
            let obj = {
                legend: {
                    data: data.map(item => item.name)
                },
                series: [{
                    data
                }]
            }
            this.pie.setOption(obj)
            this.pie1.setOption({
                graphic: {
                    elements: [{
                        type: 'image',
                        z: 4,
                        style: {
                            image: require('@/assets/images/pzmj_pie.png'),
                            width: 223,
                            height: 223,
                        },
                        left: '12.6%',
                        top: 'center',
                    }],
                },
                ...obj,
            })
        },
        initBar() {//预计产量分布
            this.bar = this.$echarts.init(document.getElementById('bar'));
            this.bar1 = this.$echarts.init(document.getElementById('bar1'));
            let legendData = ['企业数量'];
            let fontColor = '#B9C3D3';
            let option = {
                backgroundColor: 'transparent',
                grid: {
                    left: '5%',
                    right: '8%',
                    top: '12%',
                    bottom: '24%',
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                        lineStyle: {
                            color: fontColor,
                        },
                    },
                },
                legend: {
                    data: legendData,
                    right: '8%',
                    top: '2%',
                    textStyle: {
                        color: fontColor,
                    },
                },
                dataZoom: [
                    {
                        type: 'slider',
                        realtime: true,
                        startValue: 0,
                        endValue: 4,
                        xAxisIndex: [0],
                        bottom: '6%',
                        left: '8%',
                        height: 10,
                        borderColor: 'rgba(0,0,0,0)',
                        textStyle: {
                            color: '#05D5FF',
                        },
                    }
                ],
                xAxis: {
                    type: 'category',
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: fontColor,
                        },
                    },
                    offset: 0,
                    axisTick: {
                        show: false,
                        alignWithLabel: true,
                        lineStyle: {
                            color: fontColor,
                        },
                    },
                    axisLabel: {
                        fontSize: 16,
                        color: fontColor,
                    },
                    data: [],
                },
                yAxis: {
                    name: '个数',
                    nameTextStyle: {
                        color: fontColor,
                    },
                    type: 'value',
                    minInterval: 1,
                    min: 0,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: fontColor,
                        },
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            type: 'dotted',
                            color: fontColor,
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        fontSize: 16,
                        color: fontColor
                    },
                    boundaryGap: ['20%', '20%'],
                },
                series: [
                    {
                        name: legendData[0],
                        type: 'bar',
                        smooth: true,
                        symbol: 'none',
                        showSymbol: false,
                        symbolSize: 8,
                        barWidth: 10,
                        itemStyle: {
                            color: {
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                type: 'linear',
                                global: false,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: 'rgba(0,168,255,1)',
                                    },
                                    {
                                        offset: 1,
                                        color: 'rgba(0,168,255,0)',
                                    },
                                ],
                            },
                        },
                        data: [],
                    }
                ],
            };
            this.bar.setOption(option);
            this.bar1.setOption(option);
            this.bar.on('click', (params) => {
                this.$refs.enterpriseList.show({
                    type: 2,
                    title: params.name,
                    parentInfo: {
                        code: this.cityCode
                    },
                    param: {
                        companyTypeId: this.curMenu,
                        parentAreaCodes: params.data.code
                    }
                })
            })
        },
        barReload(data) {//预计产量分布重载
            let obj = {
                xAxis: {
                    data: data.xAxis.map(item => {
                        return {
                            value: item.name,
                            ...item
                        }
                    })
                },
                series: [{
                    data: data.yAxis.map((item, index) => {
                        return {
                            value: item,
                            code: data.xAxis[index].code
                        }
                    })
                }]
            }
            this.bar.setOption(obj)
            this.bar1.setOption(obj)
        },
    }
};
</script>

<style scoped lang='scss'>
.qyfb {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0 40px 0 80px;

    .top {
        display: flex;
        justify-content: center;
        height: 140px;
        box-sizing: border-box;
        padding-top: 40px;
        margin-bottom: 20px;

        .info {
            display: flex;
            align-items: flex-end;

            .title {
                font-size: 22px;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 40px;
                margin-right: 20px;
            }

            .text {
                font-size: 20px;
                background-image: linear-gradient(0deg, #FED471 0%, #FDF79E 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                margin-right: 50px;

                .num {
                    display: inline-block;
                    background-image: linear-gradient(0deg, #FED471 0%, #FDF79E 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-size: 40px;
                }
            }
        }
    }

    .bottom {
        flex: 1;
        display: flex;
        box-sizing: border-box;

        .company-wrapper {
            width: 250px;
            position: absolute;

            .icon-up,
            .icon-down {
                width: 62px;
                height: 54px;
                background-repeat: no-repeat;
                background-size: center/cover;
                cursor: pointer;
                position: absolute;
                left: 90px;

                &.disable {
                    cursor: not-allowed;
                }
            }

            .icon-up {
                top: 0;
                background-image: url("./images/icon-up-active.png");

                &.disable {
                    background-image: url("./images/icon-up-disabled.png");
                }
            }

            .icon-down {
                bottom: -120px;
                background-image: url("./images/icon-down-active.png");

                &.disable {
                    background-image: url("./images/icon-down-disabled.png");
                }
            }

            .company-cnt {
                height: 600px;
                overflow: hidden;
                position: relative;
                width: 450px;
                top: 60px;

                .company {
                    transition: all .3s;
                    position: relative;

                    .company-item {
                        width: 250px;
                        height: 64px;
                        background-repeat: no-repeat;
                        background-size: center/cover;
                        background-image: url("./images/card.png");
                        line-height: 72px;
                        text-align: center;
                        font-size: 23px;
                        color: #32EEFF;
                        cursor: pointer;
                        margin-bottom: 10px;
                        position: absolute;

                        &:hover,
                        &.active {
                            color: #fff;
                            background-image: url("./images/card-active.png");

                            .sub-menu {
                                display: block;
                            }
                        }

                        .sub-menu {
                            display: none;
                            position: absolute;
                            left: 290px;
                            top: 0;
                            width: 150px;
                            background: rgba(3, 11, 42, 0.45);
                            border: 1px solid #3271FF;
                            padding: 18px 18px 10px;
                            box-sizing: border-box;
                            z-index: 99;

                            &:before {
                                content: '';
                                position: absolute;
                                top: 0;
                                left: -50px;
                                right: 0;
                                bottom: 0;
                                z-index: -1;
                                background-image: url("./images/icon-arrow.png");
                                background-repeat: no-repeat;
                                background-position: left top 16px;
                            }

                            &.none {
                                display: none;
                            }

                            .sub-menu-item {
                                height: 25px;
                                line-height: 25px;
                                font-size: 18px;
                                margin-bottom: 8px;

                                &:hover,
                                &.active {
                                    background: url("./images/sub-menu.png") no-repeat center/cover;
                                }
                            }
                        }
                    }
                }
            }
        }

        .map {
            flex: 1;
            margin-left: 240px;
        }

        .charts {
            width: 660px;

            .pub_border {
                height: 320px;
                margin-bottom: 26px;
                background: linear-gradient(180deg, rgba(14, 47, 82, 0), rgba(14, 47, 82, 0.76));

                .pub_title {
                    cursor: pointer;
                }

                .chart {
                    height: 273px;
                }
            }
        }
    }
}
</style>
