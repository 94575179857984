<template>
    <div class="cyfb">
        <component :is="curr"></component>
        <div class="menu">
            <ul>
                <li :class="{active:curr==item.url}" @click="curr=item.url" v-for="item in navList" :key="item.title">{{item.title}}</li>
            </ul>
        </div>
    </div>
</template>

<script>
import pzcl from './pzcl'
import qyfb from './qyfb'

export default {
    name:'Cyfb',
    components: {pzcl, qyfb},
    data(){
        return {
            curr:'pzcl',
            navList:[
                {
                    title:'品种产量',
                    url:'pzcl',
                },
                {
                    title:'企业分布',
                    url:'qyfb',
                }
            ]
        }
    },
    mounted(){

    },
    methods:{

    }
}
</script>
<style lang='scss' scoped>
    .cyfb{
        width: 100%;
        height: 100%;
        position: relative;
        background: url('~@/assets/images/cyfb_bg.png') no-repeat center/100% 100%;
        .menu{
            position: absolute;
            top: 25px;
            left: 50%;
            transform: translateX(-50%);
            ul{
                // border: 1px solid #f00;
                display: flex;
                li{
                    width: 175px;
                    height: 51px;
                    font-size: 24px;
                    color: #32EEFF;
                    text-align: center;
                    line-height: 0;
                    background: url('~@/assets/images/gk_nav.png') no-repeat center/100% 100%;
                    cursor: pointer;
                }
                li:nth-child(2){
                    margin: 0 45px;
                }
                .active{
                    font-weight: bold;
                    color: #fff;
                    background: url('~@/assets/images/gk_nav_active.png') no-repeat center/100% 100%;
                }
            }
        }
    }
</style>